import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import DialogChatAPI from "../../api/dialogChatApi";
import UsersApi from "../../api/usersApi";
import ArrowLeftIcon from "../../assets/Chat/arrow-Left.svg";
import { ReactComponent as ChevronIcon } from "../../assets/Chat/chevron-up.svg";
import SearchIcon from "../../assets/Chat/magnifier.svg";
import { ReactComponent as ScrapIcon } from "../../assets/Chat/scrap.svg";
import { ReactComponent as SelectChatIcon } from "../../assets/Chat/select-chat.svg";
import CircleAvatar from "../../components/CircleAvatar/CircleAvatar";
import Input from "../../components/Input/Input";
import { network } from "../../config";
import { ContextProvider } from "../../contextProvider";
import useInfiniteScroll from "../../hooks/InfinityScroll";
import { DialogChat } from "../../types/DialogChat";
import { RealTimeBooking } from "../../types/TypeSession";
import { getTranslation } from "../../utils/getTranslation";
import socket from "../../utils/socket";
import useAutosizeTextArea from "../../utils/useAutosizeTextArea";
import FilePreview from "./Chat/FilePreview";
import MessageComponent from "./Chat/MessageComponent/MessageComponent";
import ChatContactElement from "./ChatContactElement/ChatContactElement";
import s from "./GlobalChat.module.css";

interface Message {
  messageId: string;
  userId: string;
  text: string;
  sender: string;
  files: any[];
  date: Date;
  realTimeBooking?: RealTimeBooking;
}

const { chat } = network;

const GlobalChat = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const { userIdForCreate } = location.state || {};
  const { userData } = useContext(ContextProvider);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const chatBodyRef = useRef<HTMLDivElement>(null);
  const contactsData = useRef<DialogChat[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<DialogChat[]>([]);
  const [selectedChat, setSelectedChat] = useState<DialogChat | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const [avatars, setAvatars] = useState<{ [userId: string]: string }>({});
  const [messagesData, setMessagesData] = useState<{
    [key: string]: Message[];
  }>({});
  const [selectedMessageData, setSelectedMessageData] = useState<Message[]>([]);
  const [lastMessage, setLastMessage] = useState<{
    [key: string]: { message: string; date: Date | undefined };
  } | null>(null);
  const [messagesPaggination, setMessagesPaggination] = useState(0);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [messageLoader, setMessageLoader] = useState(false);
  const [contactsLoader, setContactsLoader] = useState(false);
  const [hasChatBeenSelected, setHasChatBeenSelected] = useState(false);
  const [chatsPaggination, setChatsPaggination] = useState({
    hasMore: true,
    search: "",
  });
  const [filterUpdateCounter, setFilterUpdateCounter] = useState(0);
  const scrollPositionRef = useRef<number | null>(null);
  const [searchQuery, setSearchQuery] = useState(chatsPaggination.search);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setChatsPaggination((prev) => ({
        ...prev,
        search: searchQuery,
      }));
      setFilterUpdateCounter((prev) => prev + 1);
    }, 100); // задержка 300 мс

    return () => clearTimeout(timeout);
  }, [searchQuery]);

  const getUserChats = useCallback(
    async (page: number) => {
      if (token && userData && userData._id) {
        setContactsLoader(true);

        const response = await DialogChatAPI.getUsersDialogChats(
          token,
          userData._id,
          20,
          page,
          chatsPaggination.search
        );

        setChatsPaggination((prev) => ({
          ...prev,
          hasMore: response.chats.length === 20,
        }));
        setContactsLoader(false);
        if (response.status) {
          setFilteredContacts((prev) => {
            if (page === 0) {
              return response.chats;
            }
            return [...prev, ...response.chats];
          });
          contactsData.current =
            page === 0
              ? response.chats
              : [...contactsData.current, ...response.chats];
          /*    setContactsData(response.chats); */
        }
      }
    },
    [filterUpdateCounter]
  );
  useAutosizeTextArea(textAreaRef.current, newMessage);
  const { scrollContainerRef } = useInfiniteScroll({
    fetchData: getUserChats,
    hasMore: chatsPaggination.hasMore,
    isLoading: contactsLoader,
    initialPage: 0,
  });

  useEffect(() => {
    if (userIdForCreate && !hasChatBeenSelected) {
      // Если чат для userId уже выбран, ничего не делаем
      if (userIdForCreate === selectedChat?.members._id) return;

      (async () => {
        if (!token || !userData) return;
        const response = await DialogChatAPI.createDialogChat(token, {
          userId: userData._id,
          userIdToSend: userIdForCreate,
        });
        if (response.status && response.chat) {
          setFilteredContacts((prev) => {
            return [response.chat!, ...prev!];
          });
          contactsData.current = [response.chat, ...contactsData.current];
          setSelectedChat(response.chat);
          setHasChatBeenSelected(true);
        }
      })();

      /* if (chatForUser) {
        setSelectedChat(chatForUser);
        setHasChatBeenSelected(true);
      } */
    }
  }, [userIdForCreate, contactsData.current]);
  const handleMessage = (response: any) => {
    const message = {
      messageId: response.newMessage._id,
      userId: response.newMessage.user._id,
      text: response.newMessage.message,
      sender: "other",
      files: response.newMessage.files,
      date: response.newMessage.date,
      realTimeBooking: response.newMessage.realTimeBooking,
    };

    setMessagesData((prev) => ({
      ...prev,
      [response.newMessage.chatId]: [
        ...(prev[response.newMessage.chatId] || []),
        message,
      ],
    }));

    setLastMessage((prev) => {
      if (prev) {
        return {
          ...prev,
          [response.newMessage.chatId]: {
            message:
              message.text && message.text.length ? message.text : "File",
            date: message.date,
          },
        };
      }
      return {
        [response.newMessage.chatId]: {
          message: message.text,
          date: message.date,
        },
      };
    });

    if (response.newMessage.chatId !== selectedChat?._id) {
      const updatedDialogChats = contactsData.current.map((dialogChat) => {
        if (dialogChat._id === response.newMessage.chatId) {
          return {
            ...dialogChat,
            unreadMessages: dialogChat.unreadMessages + 1,
          };
        }
        return dialogChat;
      });
      contactsData.current = updatedDialogChats;
    }
  };

  useEffect(() => {
    socket.emit(chat.joinDialogChat, userData?._id);
    socket.on(chat.newDialogMessage, handleMessage);
    return () =>
      socket.off(chat.newDialogMessage, handleMessage) as unknown as void;
  }, []);

  useEffect(() => {
    if (!filteredContacts || !filteredContacts.length) return;
    const lastMessageDataArray = filteredContacts.map((item) => ({
      [item._id]: {
        message: !item.lastMessage
          ? ""
          : item.lastMessage &&
            item.lastMessage.labels &&
            item.lastMessage.labels.length
          ? item.lastMessage.labels.find(
              (item) => item.language === userData?.selectedLanguage
            )?.text!
          : item.lastMessage &&
            item.lastMessage.message &&
            item.lastMessage.message.length
          ? item.lastMessage.message
          : "File",
        date: item.lastMessage?.date,
      },
    }));

    const lastMessageData = lastMessageDataArray.reduce((obj, item) => {
      const key = Object.keys(item)[0];
      obj[key] = item[key];
      return obj;
    }, {});

    setLastMessage(lastMessageData);
  }, [filteredContacts]);

  useEffect(() => {
    const makeAsync = async () => {
      if (selectedChat && messagesData[selectedChat._id] && token) {
        const messageArr = messagesData[selectedChat._id]
          .filter((message) => message.sender === "other")
          .map((message) => message.messageId);
        const markedMessageResponse = await DialogChatAPI.markMessagesAsRead(
          token,
          messageArr
        );

        if (markedMessageResponse.status) {
          const updatedDialogChats = contactsData.current.map((dialogChat) => {
            if (dialogChat._id === selectedChat._id) {
              return {
                ...dialogChat,
                unreadMessages: 0,
              };
            }
            return dialogChat;
          });
          contactsData.current = updatedDialogChats;

          const updatedFilteredContacts = filteredContacts.map((dialog) =>
            dialog._id === selectedChat._id
              ? { ...dialog, unreadMessages: 0 }
              : dialog
          );
          setFilteredContacts(updatedFilteredContacts);
        }
      }
    };
    makeAsync();
    if (selectedChat && messagesData)
      setSelectedMessageData(messagesData[selectedChat._id]);
  }, [selectedChat, messagesData]);

  const getMessages = async () => {
    if (
      token &&
      selectedChat &&
      userData &&
      userData._id &&
      hasMoreMessages &&
      !messageLoader
    ) {
      setMessageLoader(true);

      const messagesHistoryResponse = await DialogChatAPI.getDialogMessagesById(
        token,
        selectedChat._id,
        50,
        messagesPaggination
      );

      if (messagesHistoryResponse.status) {
        setHasMoreMessages(messagesHistoryResponse.messages.length === 50);

        const newFormatChatHistory = messagesHistoryResponse.messages.map(
          (item) => ({
            messageId: item._id,
            userId: item.user ? item.user : "",
            text:
              item.labels &&
              item.labels.find(
                (item) => item.language === userData.selectedLanguage
              )
                ? item.labels.find(
                    (item) => item.language === userData.selectedLanguage
                  )?.text!
                : item.message,
            sender: userData?._id === item.user ? "user" : "other",
            files: item.files ? item.files : [],
            date: new Date(item.date),
            realTimeBooking: item.realTimeBooking,
          })
        );

        if (
          (messagesData[selectedChat._id] &&
            newFormatChatHistory
              .map((item) => item.messageId)
              .every(
                (newId) =>
                  !messagesData[selectedChat._id].some(
                    (existingItem) => existingItem.messageId === newId
                  )
              )) ||
          !messagesData[selectedChat._id]
        ) {
          setMessagesData((prev) => ({
            ...prev,
            [selectedChat._id]: prev[selectedChat._id]
              ? [...newFormatChatHistory.reverse(), ...prev[selectedChat._id]]
              : newFormatChatHistory.reverse(),
          }));
        }
      }

      setMessageLoader(false);
    }
  };

  const loadMoreMessages = () => {
    if (hasMoreMessages && !messageLoader) {
      setMessagesPaggination((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (messagesPaggination > 0) {
      getMessages();
    }
  }, [messagesPaggination]);

  const handleScroll = () => {
    if (chatBodyRef.current) {
      const { scrollTop, scrollHeight } = chatBodyRef.current;
      scrollPositionRef.current = scrollHeight - scrollTop;

      if (scrollTop === 0) {
        loadMoreMessages();
      }
    }
  };

  // Функция для восстановления позиции скролла
  const restoreScrollPosition = () => {
    console.log(scrollPositionRef.current);
    if (chatBodyRef.current && scrollPositionRef.current !== null) {
      chatBodyRef.current.scrollTop =
        chatBodyRef.current.scrollHeight - scrollPositionRef.current;
      scrollPositionRef.current = null;
    }
  };

  // Используем `MutationObserver` для отслеживания изменений в контейнере
  useEffect(() => {
    const observer = new MutationObserver(() => {
      restoreScrollPosition();
    });

    if (chatBodyRef.current) {
      observer.observe(chatBodyRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [selectedMessageData]);

  // Обработчик для эффекта, когда обновляются сообщения
  useEffect(() => {
    if (scrollPositionRef.current !== null) {
      restoreScrollPosition();
    }
  }, [selectedMessageData]);

  useEffect(() => {
    const container = chatBodyRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [chatBodyRef.current, messageLoader]);

  useEffect(() => {
    getMessages();
  }, [selectedChat]);

  const handleSendMessage = async () => {
    if (
      userData &&
      userData._id &&
      selectedChat &&
      (newMessage !== "" || selectedFiles.length > 0)
    ) {
      const messageText = newMessage;
      const files = selectedFiles;

      setNewMessage("");
      setSelectedFiles([]);

      const response = await DialogChatAPI.sendMessage({
        userIdToSend: selectedChat?.members._id,
        message: messageText,
        userId: userData._id,
        date: new Date(),
        files: Array.from(files).map((file: any) => {
          return { name: file.name, buffer: file, size: file.size };
        }),
      });

      if (response.status && userData) {
        /*   const files = response.newMessage?.files.length
          ? formatedFiles(response.newMessage?.files)
          : []; */

        setLastMessage((prev) => {
          if (prev) {
            return {
              ...prev,
              [selectedChat._id]: {
                message:
                  newMessage.text && newMessage.text.length
                    ? newMessage.text
                    : "File",
                date: newMessage.date,
              },
            };
          }
          return {
            [selectedChat._id]: {
              message:
                newMessage.text && newMessage.text.length
                  ? newMessage.text
                  : "File",
              date: newMessage.date,
            },
          };
        });

        const newMessage = {
          messageId: response.newMessage?._id ? response.newMessage?._id : "",
          userId: userData._id,
          text: response.newMessage?.message ?? "",
          sender: "user",
          files: response.newMessage?.files.length
            ? response.newMessage?.files
            : [],
          date: response.newMessage?.date
            ? new Date(response.newMessage?.date)
            : new Date(),
        };
        setMessagesData((prev) => ({
          ...prev,
          [selectedChat._id]: prev[selectedChat._id]
            ? [...prev[selectedChat._id], newMessage]
            : [newMessage],
        }));
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const newFiles = Array.from(files).filter(
        (file) => !file.type.includes("video")
      );

      if (newFiles.length + selectedFiles.length > 5) {
        alert("You can only select up to 5 files at a time.");
        e.target.value = ""; // Reset the input to allow re-selecting files
        return;
      }

      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };
  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setNewMessage(val);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const groupedMessages: { [date: string]: Message[] } = {};
  selectedMessageData &&
    selectedMessageData.forEach((message) => {
      const dateKey = new Date(message.date).toLocaleDateString();
      if (!groupedMessages[dateKey]) {
        groupedMessages[dateKey] = [];
      }
      groupedMessages[dateKey].push(message);
      if (messagesPaggination > 0) return;
      messagesEndRef.current?.scrollIntoView({
        /*    behavior: "smooth", */
      });
    });

  useEffect(() => {
    setFilteredContacts(contactsData.current);
    const makeAsync = async () => {
      if (token && contactsData.current.length > 0) {
        /* socket.off(chat.newDialogMessage, handleMessage);
        socket.on(chat.newDialogMessage, handleMessage); */

        if (
          contactsData.current
            .map((contact) => contact.members._id)
            //@ts-expect-error
            .includes(undefined)
        )
          return;
        const promises = contactsData.current.map((contact) =>
          UsersApi.getUserPublicAvatarAndName(contact.members._id)
        );
        const responses = await Promise.all(promises);

        const avatarsData = responses.reduce((acc, response) => {
          if (response.avatar) {
            acc[response._id] = response.avatar;
          }
          return acc;
        }, {} as { [userId: string]: string });

        setAvatars(avatarsData);
      }
    };
    makeAsync();
  }, [contactsData.current]);

  return (
    <div className={s.container}>
      <div
        className={
          selectedChat
            ? `${s.contactsBlock} ${s.mobileContactsBlockHidden}`
            : `${s.contactsBlock} ${s.mobileContactsBlockVisible}`
        }
      >
        <div className={s.searchBlock}>
          <Input
            onChangeInput={(value) => setSearchQuery(value)}
            inputValue={searchQuery}
            placeholder={"Search contacts"}
            isVisible
            required
          />
          <img src={SearchIcon} alt="" className={s.searchIcon} />
        </div>
        <div className={s.contactsListBlock} ref={scrollContainerRef}>
          {filteredContacts.map((item, index) => (
            <div
              key={index}
              role="button"
              onClick={() => {
                setHasMoreMessages(true);
                setMessagesPaggination(0);

                setSelectedChat(item);
              }}
            >
              <ChatContactElement
                avatar={avatars[item.members._id]}
                dateLastMessage={
                  lastMessage &&
                  lastMessage[item._id] &&
                  lastMessage[item._id].date
                    ? lastMessage[item._id].date
                    : item.lastMessage
                    ? item.lastMessage.date
                    : null
                }
                fullname={
                  getTranslation(
                    item.members.name,
                    userData?.selectedLanguage
                  ) +
                  " " +
                  getTranslation(
                    item.members.surname,
                    userData?.selectedLanguage
                  )
                }
                lastMessage={
                  lastMessage && lastMessage[item._id]
                    ? lastMessage[item._id].message
                    : item.lastMessage &&
                      item.lastMessage.labels &&
                      item.lastMessage.labels.find(
                        (item) => item.language === userData?.selectedLanguage
                      )
                    ? item.lastMessage.labels.find(
                        (item) => item.language === userData?.selectedLanguage
                      )?.text!
                    : item.lastMessage && item.lastMessage.message
                    ? item.lastMessage.message
                    : null
                }
                unreadMessages={item.unreadMessages}
                isSelectedChat={
                  selectedChat && selectedChat._id === item._id ? true : false
                }
              />
            </div>
          ))}
        </div>
      </div>
      {selectedChat ? (
        <div
          className={
            selectedChat
              ? `${s.messsagesBlock} ${s.mobileMessagesBlockVisible}`
              : `${s.messsagesBlock} ${s.mobileMessagesBlockHidden}`
          }
        >
          <div className={s.chatHeaderBlock}>
            <ChevronIcon
              className={s.chevronBack}
              onClick={() => setSelectedChat(null)}
            />

            <CircleAvatar
              name={getTranslation(
                selectedChat.members.name,
                userData?.selectedLanguage
              )}
              surname={getTranslation(
                selectedChat.members.surname,
                userData?.selectedLanguage
              )}
              avatarProps={avatars[selectedChat.members._id]}
            />

            <span>
              {getTranslation(
                selectedChat.members.name,
                userData?.selectedLanguage
              ) +
                " " +
                getTranslation(
                  selectedChat.members.surname,
                  userData?.selectedLanguage
                )}
            </span>
          </div>
          <div className={s.chatHistoryBlock} ref={chatBodyRef}>
            {Object.entries(groupedMessages).map(([date, messages], index) => (
              <div key={index} className={s.chatHistory}>
                <div className={s.dateSeparator}>
                  <span>{date}</span>
                </div>
                {messages.map((message, index) => (
                  <MessageComponent
                    message={message}
                    name={getTranslation(
                      selectedChat.members.name,
                      userData?.selectedLanguage
                    )}
                    surname={getTranslation(
                      selectedChat.members.surname,
                      userData?.selectedLanguage
                    )}
                    key={index}
                  />
                ))}
              </div>
            ))}
            <span ref={messagesEndRef} className={s.messageEnd}></span>
          </div>

          <div className={s.chatInputBlock}>
            <div className={s.filePreviewBlock}>
              {selectedFiles.map((file, index) => (
                <FilePreview
                  key={index}
                  file={file}
                  onDelete={() =>
                    setSelectedFiles((prevFiles) =>
                      prevFiles.filter((_, i) => i !== index)
                    )
                  }
                />
              ))}
            </div>
            <textarea
              id="review-text"
              ref={textAreaRef}
              value={newMessage}
              placeholder={"Type message..."}
              className={s.chatInput}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              rows={1}
              style={{
                resize: "none",
                overflowY: "auto",
                maxHeight: "300px",
              }}
            />

            <input
              type="file"
              id="fileInput"
              accept="image/*, application/pdf, .txt, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />

            <div className={s.actionChatBlock}>
              <ScrapIcon
                className={s.scrapIcon}
                onClick={() => document.getElementById("fileInput")?.click()}
              />
              <button onClick={handleSendMessage} className={s.sendButton}>
                <img src={ArrowLeftIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={s.messsagesBlockNotSelect}>
          <SelectChatIcon />
          <span>{"Select chat"}</span>
        </div>
      )}
    </div>
  );
};

export default GlobalChat;
