import {
  Autocomplete,
  Checkbox,
  ListItemText,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { theme } from "../../utils/theme";
import s from "../MultipleSelect/MultipleSelect.module.css";

type DropdownSelectProps = {
  data: { value: string; lable: string }[];
  setValue: (value: string[]) => void;
  value: string[];
  width?: string;
  lable?: string;
  placeholder?: string;
};

const AutocompleteSelect = ({
  data,
  setValue,
  value,
  width,
  lable,
  placeholder,
}: DropdownSelectProps) => {
  return (
    <div style={{ width: width ? width : "100%" }} className={s.container}>
      <ThemeProvider theme={theme}>
        <Autocomplete
          multiple
          options={data}
          disableCloseOnSelect
          value={data.filter((option) => value.includes(option.value))}
          getOptionLabel={(option) => option.lable}
          onChange={(event, newValue) =>
            setValue(newValue.map((item) => item.value))
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} />
              <ListItemText primary={option.lable} />
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={lable}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                style: {
                  color: "white", // Цвет текста в поле
                },
              }}
              InputLabelProps={{
                style: { color: "white" }, // Цвет текста метки
              }}
            />
          )}
          ChipProps={{
            style: {
              backgroundColor: "var(--primary-color)", // Цвет фона бейджа
              color: "white", // Цвет текста бейджа
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "white", // Цвет текста в поле
            },
            "& .MuiChip-root": {
              backgroundColor: "#1976d2", // Цвет фона бейджа
              color: "white", // Цвет текста бейджа
            },
            "& .MuiAutocomplete-tag": {
              borderRadius: "20px",
            },
          }}
          style={{
            borderRadius: "20px",
            backgroundColor: "rgb(46,46,46)",
            color: "white",
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default AutocompleteSelect;
