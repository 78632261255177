export const getTranslation = (
  translationsArray: { text: string; language: string }[] | undefined,
  selectedLang: string = "en"
): string => {
  if (!translationsArray || !selectedLang) return "";
  let text = "";
  text =
    translationsArray.find((el) => el.language === selectedLang)?.text ||
    translationsArray.find((el) => el.language === "en")?.text ||
    translationsArray[0].text;
  return text;
};
