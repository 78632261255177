import { useCallback, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PostApi from "../../api/postsApi";
import Loader from "../../components/Loader/Loader";
import useInfiniteScroll from "../../hooks/InfinityScroll";
import { Post } from "../../types/Posts";
import NewsfeedCard from "./NewsfeedCard/NewsfeedCard";
import s from "./Posts.module.css";

const Posts = () => {
  const token = localStorage.getItem("token");
  const [postsData, setPostsData] = useState<Post[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const getUnverifiedPosts = useCallback(
    async (page: number) => {
      if (!token) return;
      setLoading(true);
      const response = await PostApi.getUnverifiedPosts(token, 4, page);
      setLoading(false);
      if (response.status && response.posts) {
        setHasMore(response.posts.length === 4);
        setPostsData((prev) => [...prev, ...response.posts!]);
      }
    },
    [token]
  );

  const { scrollContainerRef } = useInfiniteScroll({
    fetchData: getUnverifiedPosts,
    hasMore,
    isLoading: loading,
  });

  const handlePostVerifyChange = async (
    postId: string,
    actionStatus: "verify" | "delete"
  ) => {
    if (!token) return;
    let responseStatus: boolean = false;
    if (actionStatus === "verify") {
      const response = await PostApi.updatePost(token, {
        _id: postId,
        isVerified: true,
      });
      responseStatus = response.status;
    }
    if (actionStatus === "delete") {
      const response = await PostApi.deletePost(token, postId);
      responseStatus = response.status;
    }
    if (!responseStatus) return;
    setTimeout(() => {
      setPostsData((prevPosts) =>
        prevPosts.filter((post) => post._id !== postId)
      );
    }, 500);
  };

  return (
    <div className={s.postsContainer} ref={scrollContainerRef}>
      {postsData && postsData.length ? (
        <TransitionGroup component={null}>
          {postsData.map((item, index) => (
            <CSSTransition
              key={item._id}
              timeout={500}
              classNames={{
                enter: s.postEnter,
                enterActive: s.postEnterActive,
                exit: s.postExit,
                exitActive: s.postExitActive,
              }}
            >
              <div className={s.postElement}>
                <NewsfeedCard
                  post={item}
                  allPosts={postsData}
                  setAllPosts={setPostsData}
                  showMedia
                  postVerifyChangAction={(actionStatus: "verify" | "delete") =>
                    handlePostVerifyChange(item._id, actionStatus)
                  } // Передаем функцию удаления
                />
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      ) : (
        <h2 className={s.noPostTitle}>No Posts</h2>
      )}
      {loading && (
        <div className={s.loaderBlock}>
          <Loader size={50} />
        </div>
      )}
    </div>
  );
};

export default Posts;
