import UsersApi from "../../api/usersApi";

const Users = () => {
  const token = localStorage.getItem("token");
  const fixAvatars = async () => {
    if (!token) return;

    const res = await UsersApi.fixUserAvatars(token);
    console.log(res);
  };
  const buidQuiz = async () => {
    await fetch("http://localhost:5002/tree-quizes/buildQuizes", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };

  async function fetchFixNamesMultilanguage() {
    try {
      const response = await fetch(
        "http://localhost:5002/deploy-fixes/fixNamesMultilanguage",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Добавьте токен, если требуется аутентификация
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Response:", data);
      return data;
    } catch (error) {
      console.error("Fetch error:", error);
      throw error;
    }
  }

  return (
    <div style={{ color: "white" }}>
      Users{" "}
      {/* <button onClick={fixAvatars}>Fix avatars</button>
      <button onClick={buidQuiz}>Build quiz</button>
      <button onClick={fetchFixNamesMultilanguage}>fix names</button> */}
    </div>
  );
};

export default Users;
