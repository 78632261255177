import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import CompanyApi from "../../../api/companyApi";
import UsersApi from "../../../api/usersApi";
import { ReactComponent as CloseIcon } from "../../../assets/General/close.svg";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import Input from "../../../components/Input/Input";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import { TypeUserData } from "../../../types/TypeUsers";
import { getTranslation } from "../../../utils/getTranslation";
import s from "./CreateCompany.module.css";

type CreateCompanyProps = {
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  setPage: Dispatch<SetStateAction<number>>;
  setReloadCompanyTriger: Dispatch<SetStateAction<number>>;
};

const CreateCompany = ({
  setModalVisible,
  setPage,
  setReloadCompanyTriger,
}: CreateCompanyProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [newCompany, setNewCompany] = useState({
    title: "",
    owner: { _id: "", name: "", surname: "", email: "" },
  });
  const [searchUsers, setSearchUsers] = useState("");
  const [usersData, setUsersData] = useState<TypeUserData[]>([]);

  const createCompany = async () => {
    if (!token || !userData) return;
    const payload = {
      userId: userData._id,
      ownerId: newCompany.owner._id,
      newCompany: { name: newCompany.title },
    };
    const response = await CompanyApi.createCompany(token, payload);
    if (response.status && response.company) {
      setModalVisible(false);
      setPage(0);
      setReloadCompanyTriger((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (newCompany.owner._id.length) {
      setSearchUsers("");
    }
  }, [newCompany]);

  useEffect(() => {
    if (!searchUsers.length) {
      setUsersData([]);
    }
    const makeAsync = async () => {
      if (!token || !userData || searchUsers.length < 1) return;
      const response = await UsersApi.findUserBySearchString(
        token,
        searchUsers,
        "false"
      );
      if (response.status && response.users) {
        setUsersData(response.users);
      }
    };
    makeAsync();
  }, [token, searchUsers]);

  return (
    <div className={s.container}>
      <h2 className={s.newCompanyTitle}>New company</h2>
      <Input
        inputValue={newCompany.title}
        onChangeInput={(value) =>
          setNewCompany((prev) => ({ ...prev, title: value }))
        }
        isVisible
        required
        placeholder="Company..."
        lable="Company name"
      />
      <div className={s.selectOwnerBlock}>
        <span>Select owner for company</span>
        <span className={s.searchDescription}>
          (You can search users by name, username or email)
        </span>
        {newCompany.owner._id.length ? (
          <div className={s.selectedUserBlock}>
            <div className={s.selectedUser}>
              <CircleAvatar
                userId={newCompany.owner._id}
                width="40px"
                height="40px"
              />
              <span>
                {newCompany.owner.name + " " + newCompany.owner.surname}
              </span>
              <span>{newCompany.owner.email}</span>
            </div>
            <CloseIcon
              className={s.closeIcon}
              onClick={() =>
                setNewCompany((prev) => ({
                  ...prev,
                  owner: { _id: "", name: "", surname: "", email: "" },
                }))
              }
            />
          </div>
        ) : (
          <Input
            inputValue={searchUsers}
            onChangeInput={(value) => setSearchUsers(value)}
            placeholder="Search..."
            isVisible
            required
            isSearchinput
          />
        )}
      </div>
      <div className={s.createBtnBlock}>
        <StandartButton
          action={createCompany}
          buttonTitle="Create"
          width="200px"
          disabled={
            !newCompany.owner._id.length || !newCompany.title.length
              ? true
              : false
          }
          tooltipTitle="Fill in all the information about the new company"
        />
        {usersData.length ? (
          <div className={s.searchResultBlock}>
            {usersData.map((item, index) => (
              <div
                key={index}
                className={
                  index === usersData.length - 1 ? s.lastUserBlock : s.userBlock
                }
                onClick={() =>
                  setNewCompany((prev) => ({
                    ...prev,
                    owner: {
                      _id: item._id,
                      name: getTranslation(item.name!),
                      surname: getTranslation(item.surname!),
                      email: item.email!,
                    },
                  }))
                }
              >
                <CircleAvatar userId={item._id} width="40px" height="40px" />
                <span>{item.name + " " + item.surname}</span>
                <span>{item.email}</span>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CreateCompany;
