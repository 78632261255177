import { ReactComponent as CloseIcon } from "../../assets/General/close.svg";
import { ReactComponent as MagnifierIcon } from "../../assets/Specialists/magnifier.svg";
import s from "./Input.module.css";

type AuthInputProps = {
  inputValue: string;
  onChangeInput: (value: string) => void;
  placeholder?: string;
  isVisible: boolean;
  required: boolean;
  lable?: string;
  bgColor?: string;
  padding?: string;
  fontSize?: string;
  margin?: string;
  type?: string;
  isSearchinput?: boolean;
  isCloseIcon?: boolean;
  closeFunction?: () => void;
  maxLength?: number;
  maxNumber?: number;
  minNumber?: number;
  disabled?: boolean;
  width?: string;
};

const Input = ({
  inputValue,
  onChangeInput,
  placeholder,
  isVisible,
  required,
  lable,
  bgColor,
  padding,
  fontSize,
  margin,
  type,
  isSearchinput,
  isCloseIcon,
  closeFunction,
  maxLength,
  maxNumber,
  minNumber,
  disabled = false,
  width = "100%",
}: AuthInputProps) => {
  const handleChange = (value: string) => {
    const numericValue = Number(value);
    if (maxNumber !== undefined && numericValue > maxNumber) {
      onChangeInput(maxNumber.toString());
    } else if (minNumber !== undefined && numericValue < minNumber) {
      onChangeInput(minNumber.toString());
    } else {
      onChangeInput(value);
    }
  };

  return (
    <div className={s.container} style={{ width: width }}>
      {lable && <span className={s.lable}>{lable}</span>}
      <input
        value={inputValue}
        type={isVisible ? type ?? "text" : "password"}
        onChange={(e) => handleChange(e.target.value)}
        className={s.input}
        placeholder={placeholder ? placeholder : ""}
        required={required}
        style={{
          letterSpacing: isVisible ? "1px" : "5px",
          backgroundColor: bgColor ?? "#2e2e2e",
          padding: padding ?? "14px 20px",
          fontSize: fontSize ?? "14px",
          margin: margin ?? "0",
          opacity: disabled ? 0.5 : 1,
        }}
        maxLength={maxLength}
        disabled={disabled}
      />
      {isSearchinput && (
        <MagnifierIcon
          className={s.searchIcon}
          style={{ top: lable ? "58%" : "30%" }}
        />
      )}
      {isCloseIcon && closeFunction && (
        <CloseIcon
          className={s.searchIcon}
          style={{ top: lable ? "58%" : "30%", cursor: "pointer" }}
          onClick={closeFunction}
        />
      )}
    </div>
  );
};

export default Input;
