import ky from "ky";
import { network } from "../config";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { SessionComment, SessionPackage } from "../types/TypeSession";
import { TimeRange } from "../types/TypeSpecialist";

const { sessions } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "session/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class SessionsApi {
  static async getSpecialistsComments(
    userId: string,
    limit: number,
    page: number,
    specialistId?: string
  ): Promise<
    TypeDefaultResponse & {
      comments: SessionComment[];
      totalAmount: number;
    }
  > {
    const params = new URLSearchParams();
    if (specialistId && specialistId.length) {
      params.append("specialistId", specialistId);
    }

    return await ky
      .get(
        `${baseURL}${
          sessions.getSpecialistsComments
        }/${userId}/${limit}/${page}?${params.toString()}`
      )
      .then((res) => res.json());
  }

  static async getSpecialistsSessionsAmount(
    userId: string,
    specialistId?: string
  ): Promise<
    TypeDefaultResponse & {
      amount: number;
    }
  > {
    const params = new URLSearchParams();
    if (specialistId && specialistId.length) {
      params.append("specialistId", specialistId);
    }
    return await ky
      .get(
        `${baseURL}${
          sessions.getSpecialistsSessionsAmount
        }/${userId}?${params.toString()}`
      )
      .then((res) => res.json());
  }

  static async getSpecialistSessionPackages(
    token: string,
    specialistUserId: string,
    specialistId?: string
  ): Promise<
    TypeDefaultResponse & {
      packages?: SessionPackage[] | null;
      overlapDays?: {
        specialist: string;
        overlaps: { day: string; overlapTime: TimeRange[] }[];
      }[];
    }
  > {
    const params = new URLSearchParams();
    if (specialistId && specialistId.length) {
      params.append("specialistId", specialistId);
    }
    return await ky
      .get(
        `${baseURL}${
          sessions.getSpecialistSessionPackages
        }/${specialistUserId}?${params.toString()}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }
}
