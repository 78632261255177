import ky from "ky";
import { network } from "../config";
import TypeDefaultResponse from "../types/TypeDefaultResponse";

import {
  EducationCertificatesResponse,
  FederationsCertificatesResponse,
  OverLaps,
  ParamsAvailable,
  QualificationCertificatesResponse,
  SpecialistData,
  SpecialistLevel,
  SpecialistTag,
  SpecialistTagsDirections,
  TypeSpecialistFilter,
  UserUpdates,
} from "../types/TypeSpecialist";
import { TypeUpdatedUserInfo, TypeUserData } from "../types/TypeUsers";

const { specialists } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "specialists/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class SpecialistApi {
  static async getFilteredSpecialists(
    token: string,
    payload: {
      userId: string;
      filters: TypeSpecialistFilter;
    }
  ): Promise<
    TypeDefaultResponse & {
      paramsAvailable: ParamsAvailable;
      users?: TypeUserData[];
      overlapDays: OverLaps[];
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.getFilteredSpecialists}`, {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getAvailableParams(
    token: string,
    payload: { param: string; filters: TypeSpecialistFilter }
  ): Promise<string[]> {
    return await ky
      .post(`${baseURL}getAvailableParams`, {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async update(
    updateData: TypeUpdatedUserInfo,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      specialistData: SpecialistData;
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.update}/${updateData._id}`, {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async getProfileChangeDiff(
    token: string,
    specialistUserId: string
  ): Promise<
    TypeDefaultResponse & {
      changes?: {
        changedValues: UserUpdates;
        changedAt: Date;
      };
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getProfileChangeDiff}/${specialistUserId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getEducationCertificates(
    token: string,
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: EducationCertificatesResponse[];
      total?: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getEducationCertificates}/${specialistId}/${page}/${amount}`,
        { headers: getAuthHeader(token) }
      )
      .then((res) => res.json());
  }

  static async getEducationPublicCertificates(
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: EducationCertificatesResponse[];
      total?: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getEducationPublicCertificates}/${specialistId}/${page}/${amount}`,
        {}
      )
      .then((res) => res.json());
  }

  static async getFederationCertificates(
    token: string,
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: FederationsCertificatesResponse[];
      total?: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getFederationCertificates}/${specialistId}/${page}/${amount}`,
        { headers: getAuthHeader(token) }
      )
      .then((res) => res.json());
  }

  static async getFederationPublicCertificates(
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: FederationsCertificatesResponse[];
      total?: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getFederationPublicCertificates}/${specialistId}/${page}/${amount}`,
        {}
      )
      .then((res) => res.json());
  }

  static async getQualificationCertificates(
    token: string,
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: QualificationCertificatesResponse[];
      total?: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getQualificationCertificates}/${specialistId}/${page}/${amount}`,
        { headers: getAuthHeader(token) }
      )
      .then((res) => res.json());
  }

  static async getQualificationPublicCertificates(
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: QualificationCertificatesResponse[];
      total?: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getQualificationPublicCertificates}/${specialistId}/${page}/${amount}`,
        {}
      )
      .then((res) => res.json());
  }

  static async getAllSpecialistLevels(payload: {
    mainSpecId?: string;
  }): Promise<
    TypeDefaultResponse & {
      levels?: SpecialistLevel[];
    }
  > {
    const params = new URLSearchParams();
    if (payload && payload.mainSpecId) {
      params.append("mainSpecId", payload.mainSpecId);
    }
    return await ky
      .get(
        `${baseURL}${specialists.getAllSpecialistLevels}?${params.toString()}`
      )
      .then((res) => res.json());
  }

  static async getSpecialistTags(
    token: string,
    payload?: {
      parentTagsIds?: string[];
      direction?: SpecialistTagsDirections;
      level?: number;
    }
  ): Promise<TypeDefaultResponse & { tags?: SpecialistTag[] }> {
    return await ky
      .post(`${baseURL}${specialists.getSpecialistTags}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }
}
