import { ReactComponent as Token } from "../../assets/General/token-icon.svg";
import { ReactComponent as Clock } from "../../assets/SpecTools/clock.svg";
import { ReactComponent as GrayVerify } from "../../assets/SpecTools/gray-verify.svg";
import { ReactComponent as PlayCircle } from "../../assets/SpecTools/play-circle.svg";
import { SessionPackage } from "../../types/TypeSession";
import { SpecialistData } from "../../types/TypeSpecialist";
import { TypeUserData } from "../../types/TypeUsers";
import s from "./PackageCard.module.css";

type PackageCardProps = {
  sessionPackage: SessionPackage;
  specialistData: SpecialistData | undefined;
  profileUserData: TypeUserData | null | undefined;
};

const PackageCard = ({
  sessionPackage,
  profileUserData,
  specialistData,
}: PackageCardProps) => {
  const getAllSessionDuration = () => {
    if (!sessionPackage.sessions.length) return 0;
    const durationArr = sessionPackage.sessions.map(
      (item) => item.datesAndTime[0].duration
    );
    let result = durationArr.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    result = Number((result / 60).toFixed(1));
    return result;
  };

  return (
    <div className={s.container}>
      <div className={s.headBlock}>
        <h2>{sessionPackage.title[0].text}</h2>
        <div className={s.priceBlock}>
          {(sessionPackage.packageCost / 100).toFixed(0)}
          <Token />
        </div>
      </div>
      <div className={s.generalInfoBlock}>
        <span>
          <PlayCircle /> {sessionPackage.sessions.length + " " + "sessions"}
        </span>
        <span>
          <Clock /> {getAllSessionDuration() + " " + "hours total duration"}
        </span>
      </div>
      {sessionPackage.description && (
        <div
          className={s.description}
          dangerouslySetInnerHTML={{
            __html: sessionPackage.description[0].text,
          }}
        ></div>
      )}

      <div className={s.footerBlock}>
        <div className={s.authorNameBlock}>
          {profileUserData &&
            profileUserData.name + " " + profileUserData.surname}
          {specialistData?.isVerified && <GrayVerify />}
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
